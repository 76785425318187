import React from 'react';
import styles from './interstitial.module.scss';

interface I_Props {
  id?: string,
  title: string,
  subtitle?: string,
  Img: any
}

const Interstitial = ({
  title,
  subtitle = "",
  Img
}: I_Props) => {
  return (
    <div className={styles.content}>
      {/* Poster */}
      <Img className={styles.image} />
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      {/* {actionHref && <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>{actionText}</button>} */}
    </div>
  );
}

export default Interstitial;
