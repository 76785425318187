import React from 'react';
import SocialLinks from '../social-links/social-links.component';
import styles from './placeholder.module.scss';

export const Preloader = ({ inlineStyles }) => (
  <div className={styles.loaderContainer} style={inlineStyles}>
    <span className={styles.loader}>
      <span className={styles.loaderInner}></span>
    </span>
  </div>
);

export const PlaceholderBanner = ({ links }) => (
  <div className={styles.containerBanner}>
    <h1>SOROB RAISSI</h1>
    <div className={styles.socialContainer}>
      <SocialLinks {...links} />
    </div>
  </div>
);
export const PlaceholderFooter = () => <div className={styles.containerFooter}></div>;
export const PlaceholderProjectCard = () => (
  <div className={styles.containerProjectCard}>
    <Preloader />
  </div>
);
export const PlaceholderGalleryPic = () => (
  <div className={styles.containerGalleryPic}>
    <Preloader />
  </div>
);
export const PlaceholderGridStack = () => (
  <div className={styles.containerGridStack}>
    <Preloader />
  </div>
);
