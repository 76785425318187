/**
 * Import SVG as React Component in order to style via CSS just like fonts.
 * Edit the SVG file to include `fill="currentColor"` on any `paths` you want styled.
 */
import { ReactComponent as CodeIcon } from 'images/icons/code-solid.svg';
import { ReactComponent as DesignIcon } from 'images/icons/palette-solid.svg';
// import { ReactComponent as GamesIcon } from 'images/icons/gamepad-solid.svg';
import { ReactComponent as CubesIcon } from 'images/icons/cubes-solid.svg';
// import { ReactComponent as FileCodeIcon } from 'images/icons/file-code-solid.svg';
import { ReactComponent as GearsIcon } from 'images/icons/gears-solid.svg';
import { ReactComponent as GithubIcon } from 'images/icons/github.svg';
// import { ReactComponent as PolygonIcon } from 'images/icons/draw-polygon-solid.svg';

let Data = {};

Data.app = {
  minProjectImageWidth: 320,
};

Data.banner = {
  imageUrl: 'images/pattern-squares-dark.svg',
  name: 'Sorob Raissi',
  tagline: 'Web Engineer',
  links: {
    linkedIn: 'https://www.linkedin.com/in/sraissi/',
    // twitter: 'https://twitter.com/SpreadShotDev',
    // twitch: 'https://www.twitch.tv/dieharders',
    // youtube: 'https://www.youtube.com/c/spreadshotstudios',
    github: 'https://github.com/dieharders?tab=repositories',
  },
};

// About-Me info
Data.about = `
My skills range from web development to interactive design
with specialization in real-time graphics.
I have production experience with front-end web technology (React/Angular).
Contributions to large team projects in defense and big tech
include autonomous vehicles, streaming video platforms and simulation training.
`;

Data.cards = [
  {
    title: 'Languages',
    icon: CodeIcon,
    skills: [
      { title: 'HTML5', stat: 11 },
      { title: 'CSS/SASS', stat: 11 },
      { title: 'JavaScript', stat: 5 },
      { title: 'TypeScript', stat: 4 },
      { title: 'Python', stat: 2 },
    ],
  },
  {
    title: 'Web Tech',
    icon: GearsIcon,
    skills: [
      { title: 'FastAPI', stat: 0.5 },
      { title: 'Node.js', stat: 2 },
      { title: 'Firebase', stat: 2 },
      { title: 'Next.js', stat: 1 },
      { title: 'React.js', stat: 4 },
      { title: 'Angular +2', stat: 2 },
      { title: 'Redux', stat: 1 },
      { title: 'Tailwind CSS', stat: 2 },
      { title: 'GIT/Perforce/SVN', stat: 10 },
      // { title: 'Socket.io', stat: 1 },
      // { title: 'RxJS', stat: 2 },
    ],
  },
  {
    title: 'Design',
    icon: DesignIcon,
    skills: [
      { title: 'Logos', stat: 5 },
      { title: 'Icons', stat: 5 },
      { title: '3d Graphics', stat: 10 },
      { title: 'Vector Graphics', stat: 10 },
      { title: 'Interactive UI', stat: 5 },
      // { title: 'Cinematics', stat: 2 },
      // { title: 'Gameplay Scripting', stat: 5 },
    ],
  },
  {
    title: 'Things I make',
    // icon: GamesIcon,
    skills: [
      'Web Apps',
      'Game Servers',
      '2d Action Games',
      '3d Action Games',
      'Single & Multiplayer Games',
    ],
  },
  {
    title: 'Web3D',
    icon: CubesIcon,
    skills: [
      'three.js',
      'Babylon.js',
      'Cesium.js',
      'react-three-fiber',
      // 'WebGL',
      'glTF',
    ],
  },
  {
    title: 'Dev Tools',
    // icon: FileCodeIcon,
    skills: [
      { title: 'Figma', stat: 4 },
      { title: 'Trello', stat: 10 },
      { title: 'Unreal Engine', stat: 8 },
      { title: 'GameMaker', stat: 4 },
      { title: 'Godot', stat: 2 },
    ],
  },
  {
    title: '3D Content',
    icon: CubesIcon,
    skills: [
      'Space Ships',
      'Realistic Environments',
      'Vehicles',
      'Stylized Characters',
      'Weapons',
      'Skyboxes',
      'Terrain',
    ],
  },
  {
    title: 'Content Creation Tools',
    icon: GearsIcon,
    skills: [
      { title: 'Maya', stat: 12 },
      { title: '3ds Max', stat: 8 },
      { title: 'Blender', stat: 5 },
      { title: 'nDo/dDo', stat: 5 },
      { title: 'Photoshop', stat: 15 },
      { title: 'zBrush', stat: 3 },
    ],
  },
];

Data.interstitial = {
  role: GithubIcon,
  title: `What I look for`,
  subtitle: `
    I am interested in opportunities to work on front-end UI's for
    interactive, multi-media or consumer apps. From established
    companies to small startups, I enjoy delivering customer pleasing features.
    My work excels at bringing attention and funding to your project.
    `,
};

Data.games = [
  {
    title: 'FlameyBird - iOS, Android, Web',
    subTitle: 'Game',
    images: [
      {
        low: 'images/games/FlameyBird/bird-1_low.webp',
        high: 'images/games/FlameyBird/bird-1_high.png',
      },
      {
        low: 'images/games/FlameyBird/bird-2_low.webp',
        high: 'images/games/FlameyBird/bird-2_high.png',
      },
      {
        low: 'images/games/FlameyBird/bird-3_low.webp',
        high: 'images/games/FlameyBird/bird-3_high.jpg',
      },
      {
        low: 'images/games/FlameyBird/bird-4_low.webp',
        high: 'images/games/FlameyBird/bird-4_high.png',
      },
    ],
    tools: ['GameMaker Studio', 'Aseprite'],
    info: [
      'A casual game built for Web, IOS and Android devices in GameMaker Studio.',
      'I created the pixel art and scripted the gameplay code.',
      'Integrated 3rd party analytics services (App Annie, Google Analytics).',
      'Implemented advertising APIs (Google Ads).',
    ],
    links: [['Trailer', 'https://www.youtube.com/watch?v=m4FnvH7_Ufs']],
    maxImageHeight: 230,
  },
  {
    title: 'Space Jammers - PC, Mac, Web, Android TV',
    subTitle: 'Game',
    images: [
      {
        low: 'images/games/SpaceJammers/action_low.gif',
        high: 'images/games/SpaceJammers/action_high.gif',
      },
      {
        low: 'images/games/SpaceJammers/screen_02_low.webp',
        high: 'images/games/SpaceJammers/screen_02_high.png',
      },
      {
        low: 'images/games/SpaceJammers/Screen_03_low.webp',
        high: 'images/games/SpaceJammers/Screen_03_high.png',
      },
      {
        low: 'images/games/SpaceJammers/Screen_04_low.png',
        high: 'images/games/SpaceJammers/Screen_04_high.png',
      },
      {
        low: 'images/games/SpaceJammers/Screen_05_low.webp',
        high: 'images/games/SpaceJammers/Screen_05_high.jpg',
      },
      {
        low: 'images/games/SpaceJammers/Screen_06_low.webp',
        high: 'images/games/SpaceJammers/Screen_06_high.png',
      },
    ],
    tools: ['GameMaker Studio', 'Aseprite', 'Node.js', 'Firebase'],
    info: [
      'A top-down looter shooter with cute mutant cats using a variety of alien weapons.',
      'I created pixel art, designed and coded the game using GameMaker Studio.',
      'Designed the storefront marketing assets (videos, images, t-shirts).',
      'The use of key marketing and in-house community outreach tools aided in successful Kickstarter campaign.',
      'I marketed the game by giving talks, exhibiting at Pax East, Boston FIG and other conventions.',
    ],
    links: [
      ['Game Page', 'https://www.spreadshotstudios.com/SpaceJammersGame.html'],
      ['Trailer', 'https://www.youtube.com/watch?v=TAHhne6L-O4'],
    ],
  },
  {
    title: 'Minecraft Sweeper - Web',
    subTitle: 'Game',
    images: [
      {
        low: 'images/games/Minesweeper/mynkraf_low.png',
        high: 'images/games/Minesweeper/mynkraf_high.png',
      },
    ],
    tools: [
      'GameMaker Studio',
      'javascript',
      'Node.js',
      'JSON Web Token',
      'Express.js',
      'Firebase',
      'Aseprite',
    ],
    info: [
      'An HTML5 Mine-Sweeper game with a +100K tile Minecraft map.',
      'Gameplay Programmer and Pixel Artist.',
      'Created an authentication server to sign in players.',
      'Built a networking server to enable online real-time multiplayer for up to 100 concurrent users.',
    ],
    links: [['Play Game', 'https://client-minesweeper.web.app']],
  },
];

Data.work = [
  {
    title: 'First Responder Sandbox Trainer',
    tools: ['Unreal Engine', 'Blueprints', 'C++'],
    images: [
      {
        low: 'images/web_apps/edge-1_low.webp',
        high: 'images/web_apps/edge-1-2_high.webp',
      },
      {
        low: 'images/web_apps/edge-2_low.webp',
        high: 'images/web_apps/edge-1-2_high.webp',
      },
      {
        low: 'images/web_apps/edge-3_low.webp',
        high: 'images/web_apps/edge-3_high.webp',
      },
      {
        low: 'images/web_apps/edge-4_low.webp',
        high: 'images/web_apps/edge-4_high.webp',
      },
    ],
    info: [
      'E.D.G.E. is a first person simulation of active shooter and terrorist scenarios for training first responders in a collaborative multiplayer environment.',
      '2 kilometer scale environments built in Unreal Engine.',
      'I was responsible for building 3d assets and textures/materials creation with Maya & Photoshop.',
      'I was also responsible for setting up a motion capture studio and record animation then cleanup the results and integrate with the Unreal engine.',
      'I also did gameplay and cinematic scripting with Unreal Engine Blueprints.',
    ],
    links: [
      ['Try App', 'https://www.cesiedgetraining.com'],
      [
        'Article',
        'https://www.dhs.gov/archive/science-and-technology/publication/st-edge-virtual-training-fact-sheets-and-video',
      ],
      [
        'Article',
        'https://www.eurogamer.net/new-mass-shooting-simulator-aims-to-help-teachers-respond-more-effectively-in-a-crisis',
      ],
      ['Video', 'https://www.dhs.gov/medialibrary/assets/videos/22858'],
      ['Article', 'https://www.dhs.gov/archive/science-and-technology/edge'],
    ],
  },
  {
    title: 'Paramount+\nStreaming App',
    tools: ['React', 'Redux', 'Typescript'],
    images: [
      {
        low: 'images/web_apps/paramountplus-1_low.webp',
        high: 'images/web_apps/paramountplus-1_high.png',
      },
      {
        low: 'images/web_apps/paramountplus-2_low.webp',
        high: 'images/web_apps/paramountplus-2_high.png',
      },
      {
        low: 'images/web_apps/paramountplus-3_low.webp',
        high: 'images/web_apps/paramountplus-3_high.png',
      },
      {
        low: 'images/web_apps/paramountplus-4_low.jpg',
        high: 'images/web_apps/paramountplus-4_high.png',
      },
    ],
    info: [
      'I worked on the front-end of the streaming video app for all set-top boxes, game consoles, and smart TVs.',
      'Responsible for troubleshooting platform specific bugs on various kinds of hardware and manufacturers.',
      'I built re-usable UI components with React/Redux for a multi-million subscriber app.',
      'Work with streaming video APIs and services specific to each hardware manufacturer (lots of 3rd party dependencies).',
      'Push for adoption of Typescript to maintain reliability in codebase.',
      'Boost user confidence by resolving issues with video streams and device performance.',
    ],
    links: [['Try App', 'https://www.paramountplus.com']],
  },
  {
    title: '3D Visualization for Autonomous Robotics',
    tools: ['Cesium.js', 'Angular', 'WebGL', 'Typescript', 'Python', 'Blender'],
    images: [
      {
        low: 'images/web_apps/USV-1_low.webp',
        high: 'images/web_apps/USV-1_high.png',
      },
      {
        low: 'images/web_apps/USV-2_low.jpg',
        high: 'images/web_apps/USV-2_high.png',
      },
      {
        low: 'images/web_apps/USV-3_low.webp',
        high: 'images/web_apps/USV-3_high.png',
      },
      {
        low: 'images/web_apps/USV-4_low.webp',
        high: 'images/web_apps/USV-4_high.png',
      },
      {
        low: 'images/web_apps/USV-5_low.webp',
        high: 'images/web_apps/USV-5_high.png',
      },
    ],
    info: [
      'I worked on a Command & Control style front-end for autonomous vehicles on land, air, sea, and space.',
      'Built a UI to display sensor data in real-time, 3d visualizations of robots with their geo-location plotted on a real-time map and an interface to issue commands to drones.',
      'I helped develop an Angular app that allows remote commanding and mission planning of AI controlled vehicles.',
      'Created interactive 3d map of earth to support mission planning and vehicle routing/navigation.',
    ],
    links: [
      ['Article', 'https://en.wikipedia.org/wiki/Sea_Hunter'],
      [
        'Article',
        'https://www.twz.com/sea/darpas-defiant-fully-uncrewed-demonstrator-ship-will-hit-the-seas-later-this-year',
      ],
      [
        'Article',
        'https://www.surfpac.navy.mil/Media/News/Article/3601631/usvdiv-1-participates-alongside-ran-in-autonomous-warrior-2023/',
      ],
    ],
  },
];

Data.examples = [
  {
    title: 'Hero API',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/rest-api_low.png',
        high: 'images/examples/rest-api_high.png',
      },
    ],
    tools: ['Heroku', 'MongoDB', 'Mongoose API', 'Express.js', 'Node.js', 'javascript'],
    info: [
      'Back-End that takes requests from a client.',
      'Sends data stored on server back to client.',
      'Built Front-End to display profile data from server.',
    ],
    links: [
      ['Demo', 'https://example-restapi-client.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-restapi-client'],
    ],
  },
  {
    title: 'CSS Playground',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/css-grid_low.png',
        high: 'images/examples/css-grid_high.png',
      },
    ],
    tools: ['javascript', 'CSS Grid'],
    info: [
      'Displays an example implementaion of CSS Grid and its code.',
      'Interactive code samples that update CSS in real-time.',
      'Buttons apply different CSS rules to the Grid.',
    ],
    links: [
      ['Demo', 'https://example-css-grid.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-css-grid'],
    ],
  },
  {
    title: 'Live Comments API',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/preview-comments_low.png',
        high: 'images/examples/preview-comments_high.png',
      },
    ],
    tools: ['Heroku', 'MongoDB', 'Mongoose API', 'Express.js', 'Node.js', 'javascript'],
    info: [
      'Add/Like/Delete a comment from Mongo database.',
      'Sorts comments based on date added or likes.',
      'Calculates and display elapsed time since post.',
    ],
    links: [
      ['Demo', 'https://example-mongo-comments-client.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-mongo-comments'],
    ],
  },
  {
    title: 'Infinite Gallery',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/image-card-gallery_low.jpg',
        high: 'images/examples/image-card-gallery_high.jpg',
      },
    ],
    tools: ['Unsplash API', 'javascript'],
    info: [
      'This Front-End queries the Unsplash API to fetch paginated photos.',
      'Infinite scrolling! Load photos when scrolled to bottom.',
      'Resizes images to fill window.',
    ],
    links: [
      ['Demo', 'https://example-image-cards.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-image-cards'],
    ],
  },
];

Data.tools = [
  {
    title: 'Local Ai Engine -\nDesktop App',
    subTitle: 'App',
    tools: ['Python', 'Typescript', 'Next.js', 'llama-index', 'llama-cpp-python'],
    images: [
      {
        low: 'images/web_apps/obrew-doc_low.webp',
        high: 'images/web_apps/obrew-doc_high.png',
      },
      {
        low: 'images/web_apps/obrew-jobs_low.webp',
        high: 'images/web_apps/obrew-jobs_high.png',
      },
      {
        low: 'images/web_apps/obrew-model_low.webp',
        high: 'images/web_apps/obrew-model_high.png',
      },
      {
        low: 'images/web_apps/obrew-features_low.webp',
        high: 'images/web_apps/obrew-features_high.png',
      },
    ],
    info: [
      'Obrew Studio runs open-source Ai locally on your device.',
      'Obrew is a meta-framework that provides tools for common problems like long term memory and hallucinations.',
      'I built the front-end and back-end engine to support running LLMs and other tools like RAG for memory recall.',
      'Built as a native app for desktop and mobile devices with an app installer.',
    ],
    links: [
      ['Try App', 'https://www.openbrewai.com'],
      ['GitHub', 'https://github.com/dieharders/brain-dump'],
      ['GitHub', 'https://github.com/dieharders/obrew-studio-server'],
    ],
    maxImageHeight: 300,
  },
  {
    title: 'Live-Stream Audience Presence Tool',
    subTitle: 'App',
    tools: ['Twitch API', 'javascript'],
    images: [
      {
        low: 'images/web_apps/twitchchat-1_low.webp',
        high: 'images/web_apps/twitchchat-1_high.png',
      },
      {
        low: 'images/web_apps/twitchchat-2_low.webp',
        high: 'images/web_apps/twitchchat-2_high.png',
      },
      {
        low: 'images/web_apps/twitchchat-3_low.webp',
        high: 'images/web_apps/twitchchat-3_high.jpg',
      },
    ],
    info: [
      'A Twitch chat dashboard that displays messages based on a users presence in the channel (if they are logged in/out).',
      'Chat members are represented by game characters on screen giving the audience a sense of "presence" during the stream.',
      'Audience avatars visually display their chat messages, providing an alternative chat experience for all.',
      'I designed & built the front-end with javascript and implemented the Twitch API to detect member information.',
    ],
    links: [
      ['Try App', 'https://twitch-chat-presence.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/twitch-chat-presence'],
    ],
    maxImageHeight: 250,
  },
  {
    title: 'Ai Menu Builder using Google Gemini',
    subTitle: 'App',
    tools: ['Typescript', 'Next.js', 'Gemini API', 'Tailwind CSS', 'Puppeteer'],
    images: [
      {
        low: 'images/web_apps/menu-1_low.jpg',
        high: 'images/web_apps/menu-1_high.png',
      },
      {
        low: 'images/web_apps/menu-2_low.jpg',
        high: 'images/web_apps/menu-2_high.png',
      },
    ],
    info: [
      'I built an interactive food menu using Google Gemini Ai. You can browse pics, nutrition info, translations, and ask questions.',
      'I built this to help people with complex dietary requirements or visually inclined people.',
      'This app gives you a shortcut for finding the information you want simply by asking in chat.',
      'It will retrieve all necessary information about the menu from the web and display it for you in any format or language you wish.',
    ],
    links: [
      ['Try App', 'https://ohmenu.openbrewai.com'],
      ['GitHub', 'https://github.com/dieharders/ai-menu-generator'],
    ],
    maxImageHeight: 400,
  },
  {
    title: 'Vue3d: Online 3d Model Viewer',
    tools: [],
    images: [
      {
        low: 'images/web_apps/vue3d-1_low.webp',
        high: 'images/web_apps/vue3d-1_high.png',
      },
      {
        low: 'images/web_apps/vue3d-2_low.jpg',
        high: 'images/web_apps/vue3d-2_high.webp',
      },
    ],
    info: [
      'An online 3d model viewer that works with locally and externally hosted files.',
    ],
    links: [['Try App', 'https://sorob.net/vue3d/create']],
  },
  {
    title: 'Native App Bootstrapper',
    tools: ['Tauri', 'Next.js', 'Tailwind CSS'],
    images: [
      {
        low: 'images/web_apps/twitchchat-1_low.png',
        high: 'images/web_apps/twitchchat-1_high.png',
      },
    ],
    info: [''],
    links: [['Github', 'https://google.com']],
  },
  {
    title: 'Fortnite Resume',
    subTitle: 'App',
    images: [
      {
        low: 'images/web_apps/fortnite-resume-thumbnail.png',
        high: 'images/web_apps/fortnite-resume-thumbnail.png',
      },
    ],
    tools: ['Github API', 'javascript'],
    info: [
      'Fetch a GitHub profile and generate a Fortnite themed resume.',
      'Designed 2d assets and built front-end.',
    ],
    links: [['GitHub', 'https://github.com/dieharders/examples-fortnite_resume_builder']],
  },
  {
    title: 'Automated Marketing',
    subTitle: 'SaaS',
    images: [
      {
        low: 'images/web_apps/spreadfluence_icon_low.jpg',
        high: 'images/web_apps/spreadfluence_icon_high.png',
      },
    ],
    tools: [
      'Twitter API',
      'Express.js',
      'Node.js',
      'Bootstrap',
      'javascript',
      'Firebase',
      'JQuery',
    ],
    info: [
      'Spreadfluence is a platform for managing and building Social Media campaigns.',
      "Originally built to automate my game's marketing.",
      'Designed front-end and server-side.',
    ],
    links: [['Try App', 'https://pushhound.firebaseapp.com']],
  },
];

Data.gallery = [
  {
    preview: 'images/games/SpaceJammers/Clint_Run.gif',
    link: 'images/games/SpaceJammers/Clint_Run.gif',
  },
  {
    preview: 'images/games/SpaceJammers/ClownBoss.gif',
    link: 'images/games/SpaceJammers/ClownBoss.gif',
  },
  {
    preview: 'images/games/SpaceJammers/bowser_run.gif',
    link: 'images/games/SpaceJammers/bowser_run.gif',
  },
  {
    preview: 'images/games/SpaceJammers/Brainiac.gif',
    link: 'images/games/SpaceJammers/Brainiac.gif',
  },
  {
    preview: 'images/cg/candle_Low.jpg',
    link: 'images/cg/candle_Hi.webp',
  },
  {
    preview: 'images/cg/Cart_Low.jpg',
    link: 'images/cg/Cart_Hi.webp',
  },
  {
    preview: 'images/cg/Brood_Low.jpg',
    link: 'images/cg/Brood_Hi.webp',
  },
  {
    preview: 'images/cg/metalDetector_Low.jpg',
    link: 'images/cg/metalDetector_Hi.webp',
  },
  {
    preview: 'images/cg/mixer_Low.jpg',
    link: 'images/cg/mixer_Hi.webp',
  },
  {
    preview: 'images/cg/portajohn_Low.jpg',
    link: 'images/cg/portajohn_Hi.webp',
  },
  {
    preview: 'images/cg/printer_Low.jpg',
    link: 'images/cg/printer_Hi.webp',
  },
  {
    preview: 'images/cg/manticore_Low.jpg',
    link: 'images/cg/manticore_Hi.webp',
    modelLink: 'models/manticore.glb',
    bgColor: '#0c021f',
    lighting: 'neutral',
  },
  {
    preview: 'images/cg/Columbian_Low.jpg',
    link: 'images/cg/Columbian_Hi.webp',
  },
  {
    preview: 'images/cg/laptop_Low.jpg',
    link: 'images/cg/laptop_Hi.webp',
    modelLink: 'models/laptop.glb',
    bgColor: 'black',
    lighting: 'studio',
  },
  {
    preview: 'images/cg/RailSword_Low.jpg',
    link: 'images/cg/RailSword_Hi.webp',
    modelLink: 'models/blade.glb',
    bgColor: 'black',
    lighting: 'neutral',
  },
  {
    preview: 'images/cg/Skimmer_Low.jpg',
    link: 'images/cg/Skimmer_Hi.webp',
    modelLink: 'models/skimmer.glb',
    bgColor: 'grey',
    lighting: 'creek',
  },
  {
    preview: 'images/cg/SodaMachine_Low.jpg',
    link: 'images/cg/SodaMachine_Hi.webp',
    modelLink: 'models/vending.glb',
    bgColor: 'black',
    lighting: 'studio',
  },
  {
    preview: 'images/cg/Truck_Low.jpg',
    link: 'images/cg/Truck_Hi.webp',
    modelLink: 'models/truck.glb',
    bgColor: 'grey',
    lighting: 'neutral',
  },
  {
    preview: 'images/cg/chair_Low.jpg',
    link: 'images/cg/chair_Hi.webp',
  },
  {
    preview: 'images/cg/walkie_Low.jpg',
    link: 'images/cg/walkie_Hi.webp',
  },
];

export default Data;
