import { useRef, useState } from 'react';
import { generateLink } from 'utils/index';
import styles from './index.module.scss';

/**
 * Allow easy way to create a link to a page that displays a hosted 3d model.
 * @TODO Allow users to add content (cameras, lights) to scene and save them locally in json.
 * @TODO Add showcase features that can export images of: auto texture teardowns, beauty shots, render turn-table anim, etc...
 */
const ModelCreatorPage = () => {
  const hostedSrcRef = useRef(null);
  const posterRef = useRef(null);
  const bgRef = useRef(null);
  const lightingRef = useRef(null);
  const [uploadMode, setUploadMode] = useState('hosted');
  const [modelUrl, setModelUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const blobUrl = URL.createObjectURL(file);
    setModelUrl(blobUrl);
  };

  const copyLinkAction = async () => {
    const src = uploadMode === 'local' ? modelUrl : hostedSrcRef.current?.value;
    const url = generateLink({
      src: src,
      poster: posterRef.current?.value,
      color: bgRef.current?.value,
      lighting: lightingRef.current?.value,
    });
    await navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log('Link copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy generated link: ', err);
      });
    return url;
  };

  const goToPreviewAction = async () => {
    const url = await copyLinkAction();
    window.open(url);
  };

  const inputLocal = (
    <>
      <div className={styles.inputTitleContainer}>
        <label htmlFor="upload-type" className={styles.inputTitle}>
          Path to local model
        </label>
      </div>
      <span className={styles.inputContainer}>
        👯‍♀️
        <input
          id="upload-type"
          className={styles.inputThing}
          type="file"
          accept=".glb,.gltf"
          onChange={handleFileChange}
        />
      </span>
    </>
  );

  const inputHosted = (
    <>
      <div className={styles.inputTitleContainer}>
        <label htmlFor="src" className={styles.inputTitle}>
          URL to hosted model
        </label>
      </div>
      <span className={styles.inputContainer}>
        👯‍♀️
        <input
          className={styles.inputThing}
          id="src"
          ref={hostedSrcRef}
          name="model_source"
          type="text"
          placeholder="https://your-host.com/model.glb"
        />
      </span>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        {/* Title and Description */}
        <h1 className={styles.title}>
          Vue3d:<br></br>Link Generator
        </h1>
        <p className={styles.text}>
          Import your files below to generate a web page to render your model on any
          device. Use the unique link in your resume or from your personal website.
        </p>
        <div className={styles.glow}></div>
        <br></br>
        {/* Chooser Buttons */}
        <div className={styles.inputTitleContainer}>
          <label htmlFor="upload-chooser" className={styles.inputTitle}>
            Import type
          </label>
          <p className={styles.inputDescr}>
            Only .glb and .gltf 2.0 formats are currently supported.
          </p>
        </div>
        <span className={styles.chooserContainer}>
          <div className={styles.chooserBtnContainer}>
            <input
              id="chooser-local"
              className={styles.radioBtn}
              type="radio"
              value="local"
              name="link-type"
              onChange={(el) => setUploadMode(el.target.value)}
            />
            <label htmlFor="chooser-local" className={styles.radioBtn}>
              Local
            </label>
          </div>
          <div className={styles.chooserBtnContainer}>
            <input
              id="chooser-hosted"
              className={styles.radioBtn}
              type="radio"
              defaultChecked
              value="hosted"
              name="link-type"
              onChange={(el) => setUploadMode(el.target.value)}
            />
            <label htmlFor="chooser-hosted" className={styles.radioBtn}>
              Hosted
            </label>
          </div>
        </span>
        {/* Local upload input */}
        {uploadMode === 'local' && inputLocal}
        {/* Hosted upload input */}
        {uploadMode === 'hosted' && inputHosted}
        {/* Poster input */}
        <div className={styles.inputTitleContainer}>
          <label htmlFor="poster" className={styles.inputTitle}>
            Path to hosted poster (Optional)
          </label>
          <p className={styles.inputDescr}>
            The poster image you define will be shown while the model loads.
          </p>
        </div>
        <span className={styles.inputContainer}>
          📸
          <input
            className={styles.inputThing}
            id="poster"
            ref={posterRef}
            name="poster_image"
            type="text"
            placeholder="https://your-host.com/poster.jpg"
          />
        </span>
        {/* background input */}
        <div className={styles.inputTitleContainer}>
          <label htmlFor="bg" className={styles.inputTitle}>
            Background color (Optional)
          </label>
        </div>
        <span className={styles.inputContainer}>
          🎨
          <input
            className={styles.inputThing}
            id="bg"
            ref={bgRef}
            name="background_color"
            type="text"
            placeholder="hex value (#fff) or name (white)"
          />
        </span>
        <div className={styles.inputTitleContainer}>
          <label htmlFor="lighting" className={styles.inputTitle}>
            Lighting (Optional)
          </label>
        </div>
        <span className={styles.inputContainer}>
          💡
          <select
            name="lighting"
            id="lighting"
            ref={lightingRef}
            className={styles.inputThing}
          >
            <option value="neutral">Neutral 😐</option>
            <option value="sunrise">Sunrise 🌅</option>
            <option value="studio">Studio 💡</option>
            <option value="creek">Creek 🌲</option>
          </select>
        </span>
        {/* Export Buttons */}
        <span className={styles.btnContainer}>
          <button className={styles.btn} onClick={copyLinkAction}>
            Copy Link
          </button>
          <button className={styles.btn} onClick={goToPreviewAction}>
            Preview
          </button>
        </span>
      </div>
      {/* Footer */}
      <footer className={styles.footer}>
        <code className={styles.attribution}>
          google{' '}
          <a
            className={styles.link}
            href="https://modelviewer.dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            model-viewer
          </a>
        </code>
        |
        <code className={styles.attribution}>
          built by{' '}
          <a
            className={styles.link}
            href="https://github.com/dieharders"
            target="_blank"
            rel="noopener noreferrer"
          >
            @DIEHARDERS
          </a>
        </code>
      </footer>
    </div>
  );
};

export default ModelCreatorPage;
