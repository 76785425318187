import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconLinkedIn } from '../../images/icons/linkedIn.svg';
import { ReactComponent as IconGithub } from '../../images/icons/github.svg';
import styles from './social-links.module.scss';

function SocialLinks(props) {
  return (
    <div className={styles.social}>
      <ul>
        {props.linkedIn && (
          <li>
            <a href={props.linkedIn} target="_blank" rel="noopener noreferrer">
              <IconLinkedIn className={styles.icon} aria-hidden="true" />
              <span className={styles.linkContentAccessible}>LinkedIn Resume</span>
            </a>
          </li>
        )}
        {props.twitter && (
          <li>
            <a href={props.twitter} target="_blank" rel="noopener noreferrer">
              <i className={styles['icon-twitter']}></i>
              <span className={styles.linkContentAccessible}>Twitter Social Media</span>
            </a>
          </li>
        )}
        {props.twitch && (
          <li>
            <a href={props.twitch} target="_blank" rel="noopener noreferrer">
              <i className={styles['icon-twitch']} aria-hidden="true"></i>
              <span className={styles.linkContentAccessible}>Twitch Social Media</span>
            </a>
          </li>
        )}
        {props.youtube && (
          <li>
            <a href={props.youtube} target="_blank" rel="noopener noreferrer">
              <i className={styles['icon-youtube']} aria-hidden="true"></i>
              <span className={styles.linkContentAccessible}>Youtube Channel</span>
            </a>
          </li>
        )}
        {props.github && (
          <li>
            <a href={props.github} target="_blank" rel="noopener noreferrer">
              <IconGithub className={styles.icon} aria-hidden="true" />
              <span className={styles.linkContentAccessible}>Github Repositories</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}

SocialLinks.propTypes = {
  linkedIn: PropTypes.string,
  twitter: PropTypes.string,
  twitch: PropTypes.string,
  youtube: PropTypes.string,
  github: PropTypes.string,
};

export default SocialLinks;
