import React, { useEffect, useState, useRef } from 'react';

interface I_Props {
  children: any,
  threshold: number,
  rootMargin: string,
  placeholder?: any
}

/**
 * Reusable LazyLoad component. Wrap any component you wish to defer loading until it's in view.
 */
const LazyComponent = ({ children, threshold = 0.0, rootMargin = '300px', placeholder }: I_Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once it's visible
        }
      },
      {
        root: null, // Observe within the viewport
        rootMargin, // higher val means load sooner
        threshold,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, [rootMargin, threshold]);

  return isVisible ? children : <span ref={elementRef}>{placeholder}</span>;
};

export default LazyComponent;
