import React, { useState, useEffect } from 'react';
import styles from './word-roller.module.scss';

const WordRoller = ({
  words = ["Story", "Pitch", "Product", "Business", "Game"],
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState(words[0]);
  const [isRolling, setIsRolling] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Start rolling animation
      setIsRolling(true);

      // After animation starts, prepare the next word
      setTimeout(() => {
        // Update the displayed word to the next in the sequence
        const nextIndex = (currentIndex + 1) % words.length;
        setDisplayedWord(words[nextIndex]);
        setCurrentIndex(nextIndex);
      }, 250); // Halfway through the animation

      // Reset rolling state after animation completes
      setTimeout(() => {
        setIsRolling(false);
      }, 500);
    }, 2000); // Change word every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, words]);

  return (
    <span className={(styles.content)} style={isRolling ? { opacity: '0', transform: 'translateY(100%)' } : { opacity: '100', transform: 'translateY(0px)' }}>
      &nbsp;{displayedWord}
    </span>
  );
};

export default WordRoller;
