import React from 'react';
import {
  PlaceholderBanner,
  PlaceholderProjectCard,
  PlaceholderGalleryPic,
} from './placeholder.component';
import LazyComponent from '../lazy-component/lazy-component';

// Use to debug Loadable components by delaying loading
// const delayedPromise = new Promise((res, rej) => {
//   setTimeout(() => {
//     res();
//   }, 100000);
// });

/**
 * These are higher-order components used to render placeholders while the module
 * for the real component is being loaded.
 */

export const LazyBanner = (props) => {
  const HeavyComponent = React.lazy(() => import('components/banner/banner.component')); // webpackChunkName: "component.banner"
  return (
    <LazyComponent placeholder={PlaceholderBanner({ ...props })}>
      <React.Suspense fallback={PlaceholderBanner({ ...props })}>
        <HeavyComponent {...props} />
      </React.Suspense>
    </LazyComponent>
  );
};
export const LazyProjectCard = (props) => {
  const HeavyComponent = React.lazy(() =>
    import('components/project-card/project-card.component'),
  ); // webpackChunkName: "component.project-card"
  return (
    <LazyComponent placeholder={PlaceholderProjectCard()}>
      <React.Suspense fallback={PlaceholderProjectCard()}>
        <HeavyComponent {...props} />
      </React.Suspense>
    </LazyComponent>
  );
};
export const LazyGalleryPic = (props) => {
  const HeavyComponent = React.lazy(() =>
    import('components/gallery-pic/gallery-pic.component'),
  ); // webpackChunkName: "component.gallery-pic"
  return (
    <LazyComponent placeholder={PlaceholderGalleryPic()}>
      <React.Suspense fallback={PlaceholderGalleryPic()}>
        <HeavyComponent {...props} />
      </React.Suspense>
    </LazyComponent>
  );
};
