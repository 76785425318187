import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import '@google/model-viewer/dist/model-viewer';
import '@google/model-viewer';
import ModelViewerPage from 'pages/model-viewer/index';
import ModelCreatorPage from 'pages/model-creator/index';
import { generateLink } from 'utils/index';
import './App.scss';

// Data
import Data from './data.js';

// Pre-loaded components
import {
  LazyBanner,
  LazyProjectCard,
  LazyGalleryPic,
} from 'components/placeholder/loadable-components';

// Normal components
import Footer from 'components/footer/footer.component';
import Interstitial from 'components/interstitial/interstitial.component';
import { SkillSlider } from 'components/skill-slider/skill-slider.component';
import WordRoller from 'components/word-roller/word-roller.component';

const is_touch_device = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};
window.is_touch_device = is_touch_device();

type T_Items = Array<any>;
type T_Selected = Array<number>;
const findItems = (items: T_Items, selectedIndexes: T_Selected) => {
  return selectedIndexes.map((itemIndex) => items?.[itemIndex]).filter((e) => e);
};
const getWorks = (items: T_Items) => {
  const list = [1, 2, 0];
  return findItems(items, list);
};
const getTools = (items: T_Items) => {
  const list = [0, 1, 2, 3];
  return findItems(items, list);
};
const getGames = (items: T_Items) => {
  const list = [1, 0];
  return findItems(items, list);
};
const getGallery = (items: T_Items) => {
  const list = [16, 11, 13, 14, 15, 17];
  return findItems(items, list);
};
const getSkills = (items: T_Items) => {
  const list = [0, 1, 4];
  return findItems(items, list);
};

const NotFoundPage = () => {
  return <div>Something went wrong...🤔</div>
};

const HomePage = () => {
  const minColumnWidth = Data.app.minProjectImageWidth;
  const projects = [
    ...getWorks(Data.work),
    // ...props.examples,
    ...getGames(Data.games),
  ];
  const tools = getTools(Data.tools);
  const skills = getSkills(Data.cards);
  const scrollToElement = useRef(null);

  // ***********************
  // Template
  // ***********************
  return (
    <div className="App disableSelect">
      <section id="lead-content" className="banner-container">
        <LazyBanner
          imageUrl={Data.banner.imageUrl}
          name={Data.banner.name}
          tagline={Data.banner.tagline}
          links={Data.banner.links}
          scrollToElement={scrollToElement}
        />
      </section>

      <div className="app-content-container">
        <section ref={scrollToElement} id="about-me" className="about-me-container">
          <div className="heading disableSelect">
            <h1 className="heading-title">
              <div style={{ height: '3rem' }}>I can help</div>
              <WordRoller
                words={[
                  'tell your Story',
                  'sell your Pitch',
                  'build your App',
                  'explain your Product',
                  'market your Business',
                  'showcase your Game',
                ]}
              />
            </h1>
            <p>{Data.about}</p>
          </div>
        </section>

        <section id="skills" className="about-container">
          {skills.map((card, index) => (
            <SkillSlider
              key={index}
              Icon={card?.icon}
              title={card.title}
              skills={card.skills}
              showNav
            />
          ))}
        </section>

        <section id="projects" className="projects-container">
          <div className="glow"></div>
          <div className="heading disableSelect">
            <h2>Work Examples</h2>
            <p>Web apps, native apps, geospatial apps, simulation tech and web games.</p>
          </div>
          <ul className="project-items-container">
            {projects.map((project, index) => (
              <li className="projects-item" key={project.title}>
                <LazyProjectCard
                  id={index}
                  title={project.title}
                  subTitle={project.subTitle}
                  images={project.images}
                  tools={project.tools}
                  info={project.info}
                  links={project.links}
                  imageWidth={minColumnWidth}
                />
              </li>
            ))}
          </ul>
        </section>

        <section id="tools" className="projects-container">
          <div className="glow"></div>
          <div className="heading disableSelect">
            <h2>Tools</h2>
            <p>Apps built to aid content creation, development or everyday life.</p>
          </div>
          <ul className="project-items-container">
            {tools.map((project, index) => (
              <li className="projects-item" key={project.title}>
                <LazyProjectCard
                  id={index}
                  title={project.title}
                  subTitle={project.subTitle}
                  images={project.images}
                  tools={project.tools}
                  info={project.info}
                  links={project.links}
                  imageWidth={minColumnWidth}
                />
              </li>
            ))}
          </ul>
        </section>

        <section id="job-role" className="interstitial-container">
          <Interstitial
            title={Data.interstitial.title}
            subtitle={Data.interstitial.subtitle}
            Img={Data.interstitial.role}
          />
        </section>

        <section id="gallery">
          <div className="glow"></div>
          <div className="heading disableSelect">
            <h2>Web3D/XR Examples</h2>
            <p>
              Real-time WebGL assets in three.js. Used in training sims and geospatial visualization apps.
            </p>
          </div>
          <ul className="gallery-container">
            {getGallery(Data.gallery).map((pic, index) => {
              const href = generateLink({
                src: pic.modelLink || '',
                poster: pic.link || '',
                color: pic.bgColor || '',
                lighting: pic.lighting || '',
              });
              return <LazyGalleryPic key={index} preview={pic.preview} href={href} />
            })}
          </ul>
        </section>
      </div>

      <section id="footer">
        <Footer {...Data.banner} />
      </section>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/vue3d/create" element={<ModelCreatorPage />} />
        <Route path="/vue3d" element={<ModelViewerPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
