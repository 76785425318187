import { PREV, NEXT, INDEX } from 'components/carousel/carousel.hooks';

// Components used in conjunction to create a simple Carousel
// @TODO Merge lazy-carousel's components into here

export const CarouselContainer = (props) => {
  const transformStyle = () => {
    if (props.children.length <= 1) return 'translateX(0%)';
    if (!props.sliding) return 'translateX(-100%)';
    if (props.dir === INDEX) return 'translateX(-100%)';
    if (props.dir === NEXT) return 'translateX(0%)';
    if (props.dir === PREV) return 'translateX(-200%)';
    return 'translateX(-100%)';
  };

  return (
    <div
      style={{
        display: 'flex',
        postion: 'relative',
        flexDirection: 'row',
        width: `${props.width}`,
        height: 'inherit',
        transition: `${props.sliding ? 'none' : `transform ${props.speed}ms ease`}`,
        transform: transformStyle(),
      }}
    >
      {props.children}
    </div>
  );
};

export const CarouselSlot = (props) => {
  return (
    <div
      className={props?.className}
      style={{
        width: `${props?.width || 'inherit'}`,
        height: `${props?.height || 'inherit'}`,
        order: `${props?.order || '0'}`,
        WebkitOrder: `${props?.order || '0'}`,
      }}
    >
      {props.children}
    </div>
  );
};
