// Utils

const parseSrc = (src: string) => {
  let result = src;
  // Parse dropbox links for them to work. This comes from these threads:
  // https://www.dropboxforum.com/discussions/101001014/cors-error-on-forced-download-links/720329
  // and
  // https://www.dropboxforum.com/discussions/101001012/shared-link--scl-to-s/689070/replies/695266#M49151
  // Correct links look like: https://dl.dropboxusercontent.com/1/view/h1swpn4er6gatod/test/astronaut.glb?rlkey=1fl0yyfn2jbwwxyv750afg2rg&dl=1
  result = result.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
  result = result.replace('&dl=0', '&dl=1'); // or &raw=1
  return result;
};

/**
 * "#" must be encoded as "%23" for hex colors
 */
export const encodeHexColor = (color = '') => {
  return color.replace('#', '%23');
};

interface I_LinkProps {
  src: string;
  host?: string;
  poster?: string;
  color?: string;
  lighting?: string;
}
export const generateLink = ({
  src,
  host = '',
  poster = '',
  color = '',
  lighting = '',
}: I_LinkProps) => {
  const protocol = location.protocol;
  const hostname = host || `${protocol}//${window.location.host}`;
  const s = parseSrc(src);
  const c = encodeHexColor(color);
  const uri = `${hostname}/vue3d?src=${s}&poster=${poster}&bg=${c}&lighting=${lighting}`;
  return encodeURI(uri);
};
