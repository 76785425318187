import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

const decodeHexColor = (color = '') => {
  return color.replace('%23', '#');
};

const setLighting = (val) => {
  switch (val) {
    case 'neutral':
      return 'neutral_HDR.jpg';
    case 'studio':
      return 'studio_small_03_1k.jpg';
    case 'sunrise':
      return 'spruit_sunrise_1k_HDR.jpg';
    case 'creek':
      return 'whipple_creek_regional_park_1k_HDR.jpg';
    default:
      return '';
  }
};

/**
 * Example: http://localhost:3000/vue3d?src=models/model.glb&bg=%23fff
 */
const ModelViewPage = () => {
  const [params] = useSearchParams();
  // Input configs
  const src = params.get('src') || '';
  const poster = params.get('poster') || '';
  const bgColor = decodeHexColor(params.get('bg')) || '#fff';
  const lighting = setLighting(params.get('lighting') || '');
  const modelRef = useRef(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = 'black';
  }, []);

  return (
    <div
      className={styles.bg}
      style={{
        backgroundColor: `${bgColor}`,
      }}
    >
      <div
        className={styles.modelContainer}
        onMouseEnter={() => {
          if (modelRef.current) modelRef.current.dismissPoster();
        }}
      >
        <model-viewer
          style={{ width: windowSize.width, height: windowSize.height }}
          src={src}
          poster={poster}
          alt="A 3d model"
          // skybox-image="environments/studio_small_03_1k.jpg" // lighting and background image
          // skybox-height="1.5m"
          environment-image={lighting ? `environments/${lighting}` : ''} // lighting without showing background image
          shadow-intensity="1.0"
          exposure="1.0"
          auto-rotate
          auto-rotate-delay="0"
          rotation-per-second="500%"
          // interaction-prompt="none"
          // orbit-sensitivity="2"
          // camera-orbit="auto 90deg auto"
          camera-controls
          // touch-action="pan-y" // allows swiping up/down to scroll page and left/right swipe to control model rotation
          // disable-tap
          // disable-pan
          // disable-zoom
          ar
          ar-modes="webxr"
          // reveal="manual" // shows model when dismissPoster() is called. Enable for lazy load.
          ref={modelRef}
        >
          {/* Follow directions here: https://modelviewer.dev/examples/postprocessing/#effects to implement. */}
          {/* <effect-composer render-mode="quality">
            <selective-bloom-effect
              strength="5"
              radius="0.7"
              threshold="0"
            ></selective-bloom-effect>
            <bloom-effect blend-mode="default"></bloom-effect>
          </effect-composer> */}
        </model-viewer>
      </div>
    </div>
  );
};

export default ModelViewPage;
