import React from 'react';
import styles from './footer.module.scss';
import Social from '../social-links/social-links.component';

const Footer = ({ links }) => {
  return (
    <footer className={styles.container}>
      <div className={[styles.left, styles.copyright].join(' ')}>
        <p>Sorob Raissi ⚛️</p>
      </div>
      <div className={styles.right}>
        <Social {...links} />
      </div>
    </footer>
  );
};

export default Footer;
