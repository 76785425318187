import React, { useCallback } from 'react';
import { CarouselContainer, CarouselSlot } from 'components/carousel/carousel.components';
import { useCarousel } from 'components/carousel/carousel.hooks';
import classNames from 'classnames';
import styles from 'components/skill-slider/skill-slider.module.scss';

export const SkillSlider = ({ Icon, title, skills, showNav = false }) => {
  const interval = 3000;
  const slides = useCallback(
    () =>
      skills.map((skill, index) => {
        const skillTitle = typeof skill === 'string' ? skill : skill.title;
        return <div key={index}>{skillTitle}</div>;
      }),
    [skills],
  );
  const { state, numItems, getOrder, renderNavTabs } = useCarousel({
    data: skills,
    showNav,
    interval,
  });
  const slideIndex = state.pos;

  const TextCarousel = (props) => {
    return (
      <div
        style={{
          width: `${props.width}`,
          height: 'inherit',
          margin: 'auto',
          marginLeft: '0',
          overflow: 'hidden',
        }}
      >
        <CarouselContainer
          speed={500}
          width={props.width}
          dir={state.dir}
          sliding={state.sliding}
        >
          {props.children.map((skill, index) => {
            return (
              <CarouselSlot
                key={index}
                width={props.width}
                height={props.height}
                order={getOrder({ index: index, pos: state.pos, numItems })}
                className={classNames(
                  styles.skill,
                  slideIndex !== index && styles.invisible,
                )}
              >
                {skill.props.children}
              </CarouselSlot>
            );
          })}
        </CarouselContainer>
      </div>
    );
  };

  const component = (
    <div className={styles.card}>
      {/* Stat & Icon */}
      <span className={styles.statContainer}>
        {/* Title Text*/}
        <h2 className={styles.title}>{title}</h2>
        <Icon
          className={styles.icon}
          alt="skill icon"
          onMouseDown={(event) => event.preventDefault()}
        />
      </span>
      {/* Description Text (slides) */}
      <TextCarousel width="20rem" height="2rem" speed={500} interval={interval}>
        {slides()}
      </TextCarousel>
      {/* Nav Dots */}
      {showNav && <span className={styles.nav}>{renderNavTabs()}</span>}
    </div>
  );

  return component;
};
